/* .app-container {
	display: flex;
	flex-direction: column;
	align-items: center;

}
.dice-area {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.dice-bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 1.5em;
}
.dice-bar img {
	display: block;
	height: auto;
}
.dice-bar > .dice-container {
	cursor: pointer;
	margin: .5em;
}
.dice-bar > .new-turn {
	margin: .5em;
	cursor: default;
}

.dice-bar > .free {
}
.dice-bar > .hold {
	background: #fb453c;
}
 */
.dice-bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 auto;
	width: 98%;
	margin-top: 10px;
}

 .dice-image {
	width: 20%;
  	/* max-width: 400px; */
  	height: auto;
 
 }

/*STYLES FOR ROLL DISPLAY AREA*/
.game {
	display: none;
}
.game-over {
	flex-direction: column;
	font-size: 1.1rem;
	font-weight: bold;
	padding: 15px;
	text-align: center;
}
.roll-area {
	display: flex;
	justify-content: center;
	margin: 15px 0px;
	width: 100%;
}
.roll-canvas {
	cursor: default;
	display: flex;
	align-items: center;
	width: 100%;
}
.roll-count {
	flex-direction: column;
	text-align: center;
	padding: 15px;
}
.roll-count h1 {
	margin: 4px;
}
.roll-count h2 {
	margin: 2px;
	font-size: 1.1rem;
}

/* STYLES FOR ROLL BUTTON */
.roll-button {
	color: #000;
	font-size: 1.4rem;
	font-weight: bold;
	height: 100px;
	cursor: pointer;
	width: 98%;
}
.not-current-turn {
	/* border: 10px solid #F00; */
}
:focus {outline:none;}
::-moz-focus-inner {border:0;}