.main {
  max-width: 815px;
  margin: 0 auto;

}
table tbody tr td {
  border: 2px solid;
  font-weight: bold;
}
p {
  font-size: 20px;
}
h1 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}
.error {
  color: red;
} 