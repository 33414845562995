th {
	border: none;
	cursor: default;
	padding: 6px;
}
td {
	/* border: 1px solid #000; */
	cursor: default;
	padding: 6px;
	max-width: 150px;
}
.scorecard-canvas {
	display: flex;
	padding: 4px;
	width: 100%;
}
.modal-div {
	border-radius: 10px;
	border: 2px solid #777;
	font-size: 1.5rem;
	padding: 10px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400px;
	max-width: 100%;
	max-height: 100%;
	text-align: center;
	z-index: 100;
	background-color: white;
}
.modal-div > button {
	font-size: 1.2rem;
	margin: 8px;
	padding: 8px;
}
.modal-overlay {
	position: fixed;
	background-color: rgba(0,0,0,0.4);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 90;
}
