.score.filled {
	border: 2px solid black;
  line-height: 1.5;
  padding: 8px;
}
.score.active {
  /* color: #fb453c; */
  background-color: #C9FFC8;
  cursor: pointer;
  padding: 8px;
}
.score.empty {
  cursor: pointer;
  opacity: 0;
  padding: 8px;
}
.score.incomplete {
  cursor: default; 
}
.score.gray {
  color: #666;
}

.score-cell {
  line-height: 1.5;
  margin-left: 2px;
  width: 70%;
}


.score-cell.active {
	/* border: 2px solid #fb453c; */
  border: 2px solid black;
}
.score-cell.empty {
	border: 2px solid black;
}

.score-row {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.score-name {
  border: 2px solid black;
  line-height: 1.5;
  padding: 8px;
  width: 64%;
}